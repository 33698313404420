import React, { Component, useEffect, useRef } from "react";
import {Link} from 'react-router-dom'
import jquery from 'jquery'
import { Helmet } from "react-helmet";
import Footer from "./layouts/Footer";
import FooterHome from "./layouts/FooterHome";
 
class Index extends Component {

    externalScript = null;

    componentDidMount(){
        setTimeout(()=>{
            if(document.getElementById("external-script")==null){
                this.externalScript = document.createElement("script")
                this.externalScript.onerror = function(){};
                this.externalScript.id = "external-script";
                this.externalScript.async = true;
                this.externalScript.type = "text/javascript";
                this.externalScript.setAttribute("crossorigin", "anonymous");
                document.body.appendChild(this.externalScript);
                this.externalScript.src = '/script.js';
            }
        }, 1000)
    }

    componentWillUnmount(){
        if(this.externalScript!=null){
            document.body.removeChild(this.externalScript);
        }
    }

    render(){
        return(
            <>
                <Helmet>
                    <script src="/backgroundVideo.js" type="text/javascript" />
                    <script>
                        {`
                            $(function(){
                                if ($.isFunction($.fn.BackgroundVideo)) {
                                    new BackgroundVideo('.bv-video', {
                                        src: [
                                            '/assets/images/scions-tech.mp4',
                                        ]
                                        });
                                }
                            });
                        `}
                    </script>
                </Helmet>

                <div className="theme-layout landing-page">
                    <div className="responsive-header">
                        <div className="mh-head first mm-sticky mh-btns-left mh-btns-right mh-sticky">
                            <span className="mh-text">
                                <a href="/" title=""><img width="200px" src={require("../assets/images/logo-big.png")} alt="" /></a>
                            </span>
                            <span className="mh-btns-right w-40"><a className="fa fa-align-justify" href="#othermenu"></a></span>
                        </div>
                    </div>

                    <nav id="othermenu" className="mm-menu mm-offcanvas mm-right">
                        <ul>
                            
                            <li>
                                <Link to="/login" title=""><i className="fa fa-sign-in"></i> &nbsp; LOGIN</Link>
                            </li>
                            <li>
                                <Link to="/register" title=""><i className="fa fa-user-plus"></i> &nbsp; JOIN US</Link>
                            </li>
                        </ul>
                    </nav>
    
                    <div className="topbar stick">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="logo" style={{marginTop:"5px"}} >
                                        <a title="" href="/"><img src={require("../assets/images/logo-big.png")} alt="" /></a>
                                    </div>
                                </div>
    
                                <div className="col-md-9 text-right">
                                    <div className="top-area" style={{width:"100%"}}>
                                        <ul className="main-menu" style={{float:"none", lineHeight:"normal", marginTop:"10px"}} >
                                            <li style={{lineHeight:"normal"}}>
                                                <Link to="/login" title="" style={{border:"1px solid #f05a24", borderRadius:"25px", padding:"10px 20px"}}><i className="fa fa-sign-in"></i> LOGIN</Link>
                                            </li>
                                            <li style={{lineHeight:"normal"}}>
                                                <Link to="/register" title="" style={{border:"1px solid #f05a24", borderRadius:"25px", padding:"10px 20px", background: "#f05a24", color:"#fff"}} ><i className="fa fa-user-plus"></i> JOIN US</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="gap100 no-gap">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="background-video">
                                    <video className="bv-video" ref="videoBg" muted></video>
                                    <div className="videobg-meta">
                                        <h3><i className="fa fa-smile-o"></i>Hi, we are SCIONS</h3>
                                        <div className="info">
                                            <span style={{fontSize:"22px", textTransform:"initial"}}>We materialize your IDEA!</span>
                                            <p className="vid-sub-heading">A place where Academia and Industry are sharing IDEAS<br /> within a Convenient and Secured Ecosystem!</p>
                                            <Link to="/register" className="btn">Become a SCIONSian</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <section style={{background:"#fff"}}>
                        <div className="gap100 no-top overlap-75">
                            <div className="container">
                                <div className="servic-category">
                                    <div className="row merged">
                                        <div className="col-lg-4">
                                            <div className="category-box">
                                                <img src="/assets/images/icn-networking.png" className="img-fluid" />
                                                <h2>NETWORK</h2>
                                                <p>SCIONS is a place where individuals, companies, universities...</p>
                                                <a href="#hm-networking" className="main-btn" title="">Read More</a>
                                            </div>
                                        </div>
    
                                        <div className="col-lg-4">
                                            <div className="category-box selected">
                                                <img src="/assets/images/icn-idea.png" className="img-fluid" />
                                                <h2>IDEATE</h2>
                                                <p>By this feature, every user can present an abstract of their ideas...</p>
                                                <a href="#hm-ideas" className="main-btn" title="">Read More</a>
                                            </div>
                                        </div>
    
                                        <div className="col-lg-4">
                                            <div className="category-box">
                                                <img src="/assets/images/icn-jobs.png" className="img-fluid" />
                                                <h2>INVEST</h2>
                                                <p>SCIONS provide the opportunity for investors to communicate...</p>
                                                <a href="#hm-jobs" className="main-btn" title="">Read More</a>
                                            </div>
                                        </div>

                                        {/* <div className="col-lg-3">
                                            <div className="category-box">
                                                <img src="/assets/images/icn-eureka.png" className="img-fluid" />
                                                <h2>EUREKA</h2>
                                                <p>SCIONS provides the opportunity for companies to interact...</p>
                                                <a href="#hm-problems" className="main-btn" title="">Read More</a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
    
                    <section style={{background:"#f1f1f1"}} className="pt-5 pb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h2>Why <span className="orange-txt">SCIONS ?</span></h2>
                                    <p style={{textAlign:"justify"}}>SCIONS is a ground-breaking platform designed to assist idea owners in successfully commercializing their ideas. Our innovative system leverages advanced communication facilities and smart algorithms to connect idea owners with specific networks such as investors, companies, and market opportunities. At SCIONS, we place utmost importance on privacy and ensure that all interactions within our platform are conducted securely and confidentially.</p>
                                    <p style={{textAlign:"justify"}}>SCIONS aims to bridge the gap between idea owners and potential stakeholders, providing a streamlined and efficient process for turning ideas into reality. By utilizing our system, idea owners gain access to a wide range of resources and opportunities that can propel their ideas to the next level.</p>
                                </div>
                                <div className="col-md-12 text-center"><img src="/assets/images/scion-tech.svg" className="img-fluid" /></div>
                            </div>
                        </div>
                    </section>
    
                    <section id="hm-networking" style={{background:"#fff"}} className="pt-5 pb-5">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6 fade-left order-md-2 text-center" data-anima="" data-time="1000" style={{animationDuration:"1000ms", transitionTimingFunction:"ease", transitionDelay:"0ms"}}>
                                    <img src="/assets/images/networking.jpg" style={{width:"60%"}} className="img-fluid" />
                                </div>
    
                                <div className="col-md-1"></div>
    
                                <div className="col-md-5 fade-right" data-anima="" data-time="1000" style={{animationDuration:"1000ms", transitionTimingFunction:"ease", transitionDelay:"0ms"}}>
                                    <h2><span className="orange-txt">NETWORK</span> WITH SCIONS</h2>
                                    <p style={{textAlign:"justify"}}>SCIONS is a one-stop center where inventors, investors, companies, universities, researchers, and industry practitioners meet and discuss their issues from multiple fields. Users can post their challenges or problems in their industry. The platform helps to find experts in the related field(s) of problems. Both parties can make connections contact each other and find the best solutions for the prescribed problems. Users can directly reach the SCIONS community of related issues.</p>
                                    <Link to="/register" className="main-btn" title="">START NETWORKING</Link>
                                </div>
                            </div>
                        </div>
                    </section>
    
                    <section id="hm-ideas" style={{background:"#f1f1f1"}} className="pt-5 pb-5">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6 text-center"><img src="/assets/images/hm-ideas.jpg" style={{width:"60%"}} className="img-fluid" /></div>
                                <div className="col-md-5">
                                    <h2><span className="orange-txt">IDEA</span> MARKETPLACE</h2>
                                    <p style={{textAlign:"justify"}}>Users can leverage our networking system to share their ideas and seek various forms of support, such as finding co-founders, experts, investors, and entrepreneurs, to complete their team circle. Additionally, they can utilize our unique artificial intelligence (AI) methodology to receive tailored propositions regarding potential investors, companies, and experts aligned with their ideas.</p>
                                    <Link to="/register" className="main-btn" title="">START AN IDEA</Link>
                                </div>
                            </div>
                        </div>
                    </section>
    
                    <section id="hm-jobs" style={{background:"#fff"}} className="pt-5 pb-5">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6 order-md-2 text-center"><img src="/assets/images/hm-jobs.jpg" style={{width:"60%"}} className="img-fluid" /></div>
                                <div className="col-md-1"></div>
                                <div className="col-md-5">
                                    <h2><span className="orange-txt">INVEST</span></h2>
                                    <p style={{textAlign:"justify"}}>The market for idea commercialization is vast and untapped. Many aspiring researchers with their patented ideas as well as entrepreneurs struggle to navigate the complex landscape of turning their ideas into viable businesses. SCIONS fills this gap by offering a user-friendly and secure platform that facilitates connections, enhances collaboration, and maximizes the potential for idea success. Our user-friendly interface and robust security measures empower investors and idea owners alike to effortlessly forge meaningful connections, guided by intelligent algorithms tailored to their needs.</p>
                                    <Link to="/register" className="main-btn" title="">START INVESTING</Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section id="hm-problems" style={{background:"#fff"}} className="pt-5 pb-5">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6"><img src="/assets/images/hm-problem.jpg" className="img-fluid" /></div>
                                <div className="col-md-5">
                                    <h2><span className="orange-txt">EUREKA</span></h2>
                                    <p style={{textAlign:"justify"}}>SCIONS provides the opportunity for companies to interact with highly skilled industry practitioners and scholars. We offer the capability to get the best innovative solutions for their problems and roadblocks. Companies can make use of the laboratories offered by the universities to work on their projects and vice versa.</p>
                                    <Link to="/register" className="main-btn" title="">START EUREKA</Link>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section id="hm-problems" className="pt-2 pb-5">

                            <div className="container">
                                
                                <h2 className="text-center mb-5 mt-5">Partner Organizations</h2>

                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-3 text-center mb-3 col-12">
                                            <img src="/assets/logos/cunchbase.jpg" className="img-thumbnail p-1" />
                                        </div>
                                        <div className="col-md-3 text-center mb-3 col-12">
                                            <img src="/assets/logos/erc.jpg" className="img-thumbnail p-1" />
                                        </div>
                                        <div className="col-md-3 text-center mb-3 col-12">
                                            <img src="/assets/logos/goroho.jpg" className="img-thumbnail p-1" />
                                        </div>
                                        <div className="col-md-3 text-center mb-3 col-12">
                                            <img src="/assets/logos/adhi-tama.jpg" className="img-thumbnail p-1" />
                                        </div>
                                        <div className="col-md-3 offset-md-3 text-center mb-3 col-12">
                                            <img src="/assets/logos/vdezine.jpg" className="img-thumbnail p-1" />
                                        </div>
                                        <div className="col-md-3 text-center mb-3 col-12">
                                            <img src="/assets/logos/kennispark.jpg" className="img-thumbnail p-1" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div style={{display:"flex", justifyContent:"center"}} >
                                <div style={{display:"block"}}>
                                    <div style={{display:"flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around"}} >
                                        <img src="/assets/logos/erc.jpg" className="img-thumbnail logo-plan2 p-1" />
                                        <img src="/assets/logos/goroho.jpg" className="img-thumbnail logo-plan2 p-1" />
                                        <img src="/assets/logos/adhi-tama.jpg" className="img-thumbnail logo-plan2 p-1" />
                                        <img src="/assets/logos/vdezine.jpg" className="img-thumbnail logo-plan2 p-1" />
                                    </div>
                                </div>
                            </div> */}
                    </section>
    
                    <FooterHome />
                </div>
            </>
        )
    }

};

export default Index;